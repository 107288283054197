import request from '@/api/utils';

export function getRoleList(formdata = {}, method = 'post') {
  const url = '/api/user/role';
  return request.Api(url, formdata, method);
}


export function getUserList(formdata = {}, method = 'post') {
  const url = '/api/user/list';
  return request.Api(url, formdata, method);
}


export function userCreate(formdata = {}, method = 'post') {
  const url = '/api/user/create';
  return request.Api(url, formdata, method);
}

export function userEdit(formdata = {}, method = 'post') {
  const url = '/api/user/edit';
  return request.Api(url, formdata, method);
}

export function userDelete(formdata = {}, method = 'post') {
  const url = '/api/user/delete';
  return request.Api(url, formdata, method);
}


 
 