<template>
  <div>
    <v-col>
      <v-card class="">
        <v-card-subtitle>
          <span class="blue--text text-h6"> Kullanıcı Ekle</span>
        </v-card-subtitle>
        <v-divider />
        <v-card-text class="mt-0 pt-0">
          <div class="col-12 d-flex">
            <v-btn
              v-if="btnControl()"
              class="mt-2"
              outlined
              color="indigo"
              dense
              @click="dialog = true"
            >
              <v-icon>mdi-account</v-icon>
              Ekle
            </v-btn>

            <v-spacer />
            <v-col cols="2">
              <v-select
                v-model="userRoleFilter"
                :items="userRoleList"
                label="Kullanıcı Rolü"
                class="mb-0 mt-0 pt-0 pb-0"
                outlined
                dense
                required
                multiple
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="index === 0"
                    style="max-width: 120px"
                    class="d-inline-block text-truncate"
                    >{{ item.text }}</span
                  >
                  <span v-if="index === 1" class="grey--text text-caption"
                    >(+{{ userRoleFilter.length - 1 }})</span
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="3" class="d-flex">
              <v-text-field
                v-model="search"
                label="Ara"
                single-line
                outlined
                dense
                hide-details
                append-outer-icon="mdi-account-search"
              />
            </v-col>
          </div>
        </v-card-text>

        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="itemPerPage"
          class="elevation-1"
          dense
        >
          <template v-slot:item.clientId="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-dialog v-model="dialog" persistent max-width="700px">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Kullanıcı Ekle
          </v-card-title>

          <v-card-text>
            <v-row id="userForm">
              <v-form ref="form" v-model="valid" class="col-12 mt-3" :lazy-validation="lazy">
                <v-col cols="12 ">
                  <v-col class="ml-1 mr-1 mb-0 mt-0 pt-0 pb-0">
                    <v-text-field
                      ref="username"
                      v-model="username"
                      :counter="50"
                      :rules="usernameRules"
                      outlined
                      dense
                      hidden-footer
                      hidden-details
                      hidden-hint
                      label="Kullanıcı Adı (mail)"
                      required
                    />
                  </v-col>

                  <div class="d-flex mb-0 mt-0 pt-0 pb-0">
                    <v-col cols="6">
                      <v-text-field
                        ref="firstname"
                        v-model="firstname"
                        :counter="50"
                        :rules="surnameRules"
                        outlined
                        dense
                        hidden-footer
                        hidden-details
                        hidden-hint
                        label="Adı"
                        required
                      />

                      <v-text-field
                        ref="password"
                        v-model="password"
                        outlined
                        dense
                        hidden-footer
                        hidden-details
                        hidden-hint
                        label="Şifre"
                        required
                      />

                      <v-select
                        ref="companyName"
                        v-model="companyName"
                        :items="companyList"
                        label="Şirket"
                        outlined
                        multiple
                        dense
                        hidden-footer
                        hidden-details
                        hidden-hint
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        ref="lastname"
                        v-model="lastname"
                        :counter="50"
                        :rules="surnameRules"
                        outlined
                        dense
                        hidden-footer
                        hidden-details
                        hidden-hint
                        label="Soyadı"
                        required
                      />

                      <v-select
                        ref="userRole"
                        v-model="userRoleFilter"
                        :items="userRoleList"
                        label="Kullanıcı Rolü"
                        outlined
                        dense
                        required
                        hidden-footer
                        hidden-details
                        hidden-hint
                      />

                      <v-select
                        v-model="farmModel"
                        :items="farmList"
                        label="Saha Ekle"
                        outlined
                        dense
                        multiple
                        hidden-footer
                        hidden-details
                        hidden-hint
                      />
                    </v-col>
                  </div>

                  <v-col class="col-12 d-flex">
                    <v-col class="ml-1 mr-1 mb-0 mt-0 pt-0 pb-0">
                      <v-combobox
                        ref="email"
                        v-model="email"
                        :items="mail_list"
                        chips
                        clearable
                        :label="$t('email')"
                        multiple
                        outlined
                        hidden-footer
                        hidden-details
                        hidden-hint
                        :rules="emailRules"
                        dense
                      >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip
                            small
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            dense
                            @click="select"
                            @click:close="remove(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-col>
                </v-col>
              </v-form>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Kapat </v-btn>
            <v-btn v-if="!update" color="blue darken-1" text @click="createUser">
              Kayıt
            </v-btn>

            <v-btn v-if="update" color="blue darken-1" text @click="updateUser">
              Güncelle
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import i18n from "@/locale/index";
import { getCompanyFarmList } from "@/api/Request/farm";
import { getCompanyList } from "@/api/Request/company";
import { getRoleList, getUserList, userCreate, userEdit, userDelete } from "@/api/Request/user";

export default {
  data: () => ({
    headers: [],
    desserts: [],
    itemPerPage: 15,
    dialog: false,

    lastname: "",
    firstname: "",
    username: "",
    prefix: "",
    clientId: "",
    userRole: ["manager"],
    status: "",
    phone: "",

    prefixList: [],
    password: "",
    passwordconfirm: "",
    search: "",
    company_name: "",
    valid: true,
    lazy: true,
    firstnameRules: [
      v => !!v || i18n.t("Bu alan boş bırakılamaz."),
      v => (v && v.length <= 50) || i18n.t("name_50")
    ],
    userroleRules: [v => !!v || i18n.t("Bu alan boş bırakılamaz.")],
    userstatuRules: [v => !!v || i18n.t("Bu alan boş bırakılamaz.")],

    surnameRules: [
      v => !!v || i18n.t("Bu alan boş bırakılamaz."),
      v => (v && v.length <= 50) || i18n.t("surname_50")
    ],
    gsmRules: [
      v => !!v || i18n.t("Bu alan boş bırakılamaz."),
      v => (v && v.length == 16) || i18n.t("gsm_11")
    ],
    passwordRules: [
      v => {
        if (v != null && v) {
          const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;

          if (v.length > 0) {
            if (v.match(passw)) {
              return true;
            }
            return i18n.t(
              "Şifreniz en az 8, en fazla 20 karakter olmalıdır.En az bir büyük harf,bir küçük harf, bir rakam ve bir özel karakter (! ? $ ? % ^ & * ( ) _ - + = { [ } ] : ; @ ' ~ # | < , > . ? /) içermelidir."
            );
          }

          if (v.length > 0 && v.length < 8) {
            return i18n.t("Şifre en az 8 karakter olmalıdır.");
          }
          if (v.length > 20) {
            return i18n.t("Şifre en az 20 karakter olmalıdır.");
          }
          return true;
        }
        return i18n.t("This field cannot be left blank.");
      }
    ],
    usernameRules: [
      v => {
        if (!v || v.length < 1) return i18n.t("Bu alan boş bırakılamaz.");
        if (v.length > 0) {
          if (
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
              v
            )
          ) {
            return i18n.t("Kullanıcı adınız email olmalıdır.");
          }
          return true;
        }
        return true;
      }
    ],
    emailRules: [
      v => {
        if (!v || v.length < 1) return i18n.t("Bu alan boş bırakılamaz.");
        if (v.length > 0) {
          for (let i = 0; i < v.length; i++) {
            if (
              !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
                v[i]
              )
            ) {
              this.remove(i);
            }
          }
          return true;
        }
        return true;
      }
    ],
    passConfirmRules: [
      v => {
        if (!v) {
          return i18n.t("This field cannot be left blank.");
        }
        if (this.password_val != v) {
          return i18n.t("Şifreler uyuşmuyor.");
        }
        return true;
      }
    ],

    userRoleList: [],
    edit: false,
    editdialog: false,
    farmList: [],
    farmModel: [],
    update: false,
    isAdmin: false,
    managerModel: null,
    companyCode: null,
    managerList: [],
    mail_list: [],
    email: [],
    companyName: [],
    companyList: [],
    userRoleFilter: []
  }),
  watch: {
    companyName() {
      setTimeout(() => {
        this.getFarmList();
      }, 500);
    },
    userRoleFilter() {
      this.getUser();
    }
  },
  created() {},
  mounted() {
    this.getRole();
    this.getUser();
    this.getCompanyLists();
  },
  methods: {
    btnControl() {
      const role = this.$store.getters.roles[0];
      const roleList = ["admin", "manager"];

      if (roleList.indexOf(role) > -1) {
        return true;
      }
      return false;
    },
    getRole() {
      getRoleList().then(res => {
        const role = [];
        const rl = [];
        res.data.data.forEach(rol => {
          if (rol.rolename !== "admin") {
            role.push({
              text: rol.roletext,
              value: rol.rolename,
              roleId: rol.roleId
            });
            rl.push(rol.rolename);
          }
        });

        this.userRoleList = role;
        this.userRoleFilter = ["manager"];
        console.log(this.userRoleFilter);
      });
    },
    getUser() {
      console.log(this.userRoleFilter);
      if (this.userRoleFilter.length === 0) {
        console.log(this.$store.getters);
        if (this.$store.getters.roles === "admin") {
          this.userRoleFilter = [];
        } else {
          this.userRoleFilter = this.$store.getters.roles;
        }
      }
      console.log(this.userRoleFilter);
      const params = {
        condiniton: {
          companyName: this.$store.getters.companyName,
          userRole: this.userRoleFilter
        }
      };
      console.log(params);
      this.headers = [
        { text: "Kullanıcı Adı", value: "username" },
        {
          text: "Adı",
          value: "firstname"
        },
        { text: "Soyadı", value: "lastname" },
        { text: "Durum", value: "status" },
        { text: "Rolü", value: "userRole" },
        { text: "email", value: "email" },

        { text: "", value: "clientId" }
      ];

      getUserList(params).then(res => {
        this.desserts = res.data.data;
        console.log(this.desserts);
      });
    },

    getCompanyLists() {
      const params = {};
      getCompanyList(params).then(res => {
        const companyList = [];
        Object.keys(res.data.data).forEach(i => {
          companyList.push({
            text: res.data.data[i].companyName,
            value: res.data.data[i].companyCode
          });
        });
        this.companyList = companyList;
      });
    },

    getFarmList() {
      const params = {
        condiniton: {
          companyCodeList: this.companyName,
          status: 'Active',
        }
      };
      this.farmList = [];
      getCompanyFarmList(params).then(res => {
        const farmlist = [];
        Object.keys(res.data.data).forEach(i => {
          farmlist.push({
            text: res.data.data[i].farmName,
            value: res.data.data[i].prefix
          });
        });
        this.farmList = farmlist;
      });
    },

    createUser() {
      const params = {
        data: {
          username: this.username,
          lastname: this.lastname,
          firstname: this.firstname,
          userRole: this.userRoleFilter,
          password: this.password,
          prefixList: this.farmModel,
          companyCodeList: this.companyName,
          email: this.email
        }
      };

      if (this.$refs.form.validate()) {
        userCreate(params).then(res => {
          // console.log(res);
          this.getUser();
        });
      }
    },
    close() {
      this.update = false;
      this.dialog = false;
    },
    editItem(item) {
      console.log(item);
      this.username = item.username;
      this.lastname = item.lastname;
      this.firstname = item.firstname;
      this.userRoleFilter = item.userRole;
      this.password = item.password;
      this.clientId = item.clientId;
      this.email = item.email;
      this.companyName = item.companyCodeList;

      this.farmModel = item.prefixList;
      console.log(this.companyName);
      console.log(this.farmModel);
      this.update = true;
      this.dialog = true;
    },
    updateUser() {
      const params = {
        condiniton: {
          clientId: this.clientId
        },
        data: {
          username: this.username,
          lastname: this.lastname,
          firstname: this.firstname,
          userRole: this.userRoleFilter,

          email: this.email,
          prefixList: this.farmModel,
          companyCodeList: this.companyName
        }
      };
      if (this.password !== undefined) {
        params.data.password = this.password;
      }
      console.log(this.userRoleFilter);
      console.log(params);

      userEdit(params).then(res => {
        // console.log(res);
        this.getUser();
      });
    },

    deleteItem(user) {
      const params = {
        condiniton: {
          clientId: user.clientId
        }
      };
      userDelete(params).then(res => {
        console.log(res);
        this.getUser();
      });
    }
  }
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
</style>
